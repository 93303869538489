import { createIcon } from '@chakra-ui/react'

export const CompleteCheckCircleIcon = createIcon({
  displayName: 'CompleteCheckCircleIcon',
  viewBox: '0 0 28 28',
  path: (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9522 20.7214L12.2893 21.0585L12.6421 20.7378L22.8231 11.4821L23.1931 11.1457L22.8566 10.7757L20.6488 8.34838L20.3125 7.9786L19.9426 8.31484L12.4295 15.1446L8.84849 11.5636L8.49494 11.2101L8.14138 11.5636L5.82143 13.8836L5.46788 14.2371L5.82143 14.5907L11.9522 20.7214ZM0.5 14C0.5 6.58653 6.58653 0.5 14 0.5C21.4135 0.5 27.5 6.58653 27.5 14C27.5 21.4135 21.4135 27.5 14 27.5C6.58653 27.5 0.5 21.4135 0.5 14Z"
        fill="#51B051"
        stroke="#51B051"
      />
    </svg>
  )
})
