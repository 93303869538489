import { createIcon } from '@chakra-ui/react'

export const RefreshIcon = createIcon({
  displayName: 'RefreshIcon',
  viewBox: '0 0 24 25',
  path: (
    <>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 4.5V10.5H17"
          stroke="#6E7DCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 20.5V14.5H7"
          stroke="#6E7DCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.51 9.49763C4.01717 8.06442 4.87913 6.78304 6.01547 5.77305C7.1518 4.76307 8.52547 4.0574 10.0083 3.72189C11.4911 3.38639 13.0348 3.43198 14.4952 3.85441C15.9556 4.27684 17.2853 5.06235 18.36 6.13763L23 10.4976M1 14.4976L5.64 18.8576C6.71475 19.9329 8.04437 20.7184 9.50481 21.1409C10.9652 21.5633 12.5089 21.6089 13.9917 21.2734C15.4745 20.9379 16.8482 20.2322 17.9845 19.2222C19.1209 18.2122 19.9828 16.9308 20.49 15.4976"
          stroke="#6E7DCA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
})
