import useSWR from 'swr'
import { useApiClient } from './useApiClient'

export const useReportEstimatedIncomeTax = () => {
  const { apiClient } = useApiClient()

  const { data, mutate } = useSWR(`/report/estimated_income_tax`, async () => {
    const res = await apiClient.getReportEstimatedIncomeTax()
    return res
  })

  return {
    mutate,
    reportEstimatedIncomeTax: data
  }
}
