import { useBoolean } from '@chakra-ui/react'
import { createContext, useContext, useState } from 'react'
import { ItemOfAccount, useAccounts } from 'src/hooks/useAccounts'
import { ERROR_STATUSES } from 'src/interfaces/api/GetAccounts'

type TaxTaskPageContextType = {
  isSyncing: boolean
  onSyncing: () => void
  offSyncing: () => void
  failedAccounts: ItemOfAccount[] | undefined
  failedAccountConnection: boolean | undefined
  stepIndex: number
  setStepIndex: (nextValue: any) => void
}

const TaxTaskPageContext = createContext<TaxTaskPageContextType>(
  {} as TaxTaskPageContextType
)

export const TaxTaskPageContextProvider: React.FC = ({ children }) => {
  const [isSyncing, { on: onSyncing, off: offSyncing }] = useBoolean(false)

  const { accounts } = useAccounts()
  const failedAccounts = accounts?.items?.filter((account) =>
    ERROR_STATUSES.includes(account.at_status || '')
  )
  const failedAccountConnection = failedAccounts && failedAccounts.length > 0
  const [stepIndex, setStepIndex] = useState(-1)

  return (
    <TaxTaskPageContext.Provider
      value={{
        isSyncing,
        onSyncing,
        offSyncing,
        failedAccounts,
        failedAccountConnection,
        stepIndex,
        setStepIndex
      }}
    >
      {children}
    </TaxTaskPageContext.Provider>
  )
}

export const useTaxTaskPageContext = () => {
  const context = useContext(TaxTaskPageContext)
  if (!context)
    throw new Error(
      'Tax Task Page Context must be used inside GlobalContextProvider'
    )
  return context
}
