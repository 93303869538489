import { createIcon } from '@chakra-ui/react'

export const CautionIcon = createIcon({
  displayName: 'CautionIcon',
  viewBox: '0 0 20 20',
  path: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2432_28225)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8097 0.770976C10.8299 -0.208816 9.24136 -0.208815 8.26157 0.770978L0.770488 8.26206C-0.209304 9.24185 -0.209303 10.8304 0.770489 11.8102L8.26157 19.3013C9.24136 20.2811 10.8299 20.2811 11.8097 19.3013L19.3008 11.8102C20.2806 10.8304 20.2806 9.24185 19.3008 8.26206L11.8097 0.770976ZM9.03202 6.02187C9.03202 5.46761 9.48133 5.0183 10.0356 5.0183C10.5898 5.0183 11.0391 5.46761 11.0391 6.02187V10.0361C11.0391 10.5904 10.5898 11.0397 10.0356 11.0397C9.48133 11.0397 9.03202 10.5904 9.03202 10.0361V6.02187ZM11.0391 14.0504C11.0391 14.6047 10.5898 15.054 10.0356 15.054C9.48133 15.054 9.03202 14.6047 9.03202 14.0504C9.03202 13.4961 9.48133 13.0468 10.0356 13.0468C10.5898 13.0468 11.0391 13.4961 11.0391 14.0504Z"
          fill="#D82C0D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2432_28225">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
})
