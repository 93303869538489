import { createIcon } from '@chakra-ui/react'

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 15 11',
  path: (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.16666 5.17741L5.33332 9.34408L13.6667 1.01074"
        stroke="#6E7DCA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})
