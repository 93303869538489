import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  endOfYear,
  format,
  getDayOfYear,
  getDaysInYear,
  isSameYear,
  setYear,
  startOfYear
} from 'date-fns'
import React, { useMemo } from 'react'
import { useWindowSize } from 'react-use'
import {
  Area,
  CartesianGrid,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  XAxis
} from 'recharts'
import { HiddenInMobile } from 'src/components/common/HiddenInMobile'
import { CheckIcon } from 'src/components/common/Icons/CheckIcon'
import { RefreshIcon } from 'src/components/common/Icons/RefreshIcon'
import { useAccounts } from 'src/hooks/useAccounts'
import { useProcessingYear } from 'src/hooks/useProcessingYear'
import { useReportEstimatedIncomeTax } from 'src/hooks/useReportEstimatedIncomeTax'
import { refreshIconAnimation } from 'src/styles/animations'
import { useTaxTaskPageContext } from '../../contexts/TaxTaskPageContext'
import { EncourageConnectionModal } from '../EncourageConnectionTooltip/EncourageConnectionModal'

const PriceSvg = (props: JSX.IntrinsicElements['text'] & { value: number }) => {
  return (
    <text
      fill="#6E7DCA"
      textAnchor="end"
      dominantBaseline="alphabetic"
      fontWeight={800}
      fontSize="22px"
      fontFamily="Open Sans"
      {...props}
    >
      <tspan
        fill="#6E7DCA"
        fontSize="16px"
        textAnchor="end"
        dominantBaseline="alphabetic"
        fontWeight={800}
        fontFamily="Open Sans"
      >
        ¥
      </tspan>
      {props.value.toLocaleString()}
    </text>
  )
}

const nowDateObject = new Date()
const todayString = format(nowDateObject, 'MM/dd')

const CHART_WIDTH = '244px'

export const TaxChart: React.FC<{ isDemo: boolean }> = React.memo(
  ({ isDemo }) => {
    const { isSyncing } = useTaxTaskPageContext()

    const { processing_year, processing_year_lastday } = useProcessingYear()
    const { reportEstimatedIncomeTax } = useReportEstimatedIncomeTax()
    const { isNotConnected } = useAccounts()
    const isEmpty =
      // 未連携の場合はグラフは何も値が入っていない状態と同列に扱う
      // 未連携でも仕訳を手動で入力した場合、納税予測の値はプラスの値を取る
      isNotConnected ||
      (reportEstimatedIncomeTax?.current_income_tax !== 0 &&
        !reportEstimatedIncomeTax?.current_income_tax)
    const isMinusTax =
      !isEmpty && reportEstimatedIncomeTax.current_income_tax < 0
    const { width } = useWindowSize()

    /**
     * 今年が全体として何日あるか（うるう年があるため計算する）
     */
    const daysCountInYear = getDaysInYear(
      setYear(
        nowDateObject,
        processing_year?.processing_year || nowDateObject.getFullYear()
      )
    )
    /**
     * 今日が全体のうち何日目か
     */
    const indexOfToday = useMemo(
      () =>
        getDayOfYear(
          setYear(
            !isEmpty ? nowDateObject : new Date('2022/10/30'),
            processing_year?.processing_year || nowDateObject.getFullYear()
          )
        ),
      [isEmpty, processing_year]
    )

    /**
     * 1/1の日付を会計年度を含めつくる
     */
    const startOfYearString = format(
      startOfYear(
        setYear(
          nowDateObject,
          processing_year?.processing_year || nowDateObject.getFullYear()
        )
      ),
      'yyyy/MM/dd'
    )

    /**
     * 現実の時間が会計年度と異なるか
     */

    const isTodaySameYearAsProcessingYear = isSameYear(
      nowDateObject,
      new Date(processing_year_lastday)
    )
    const {
      isOpen: isModalOpen,
      onOpen: onModalOpen,
      onClose: onModalClose
    } = useDisclosure()

    return (
      <Box
        width={['100%', CHART_WIDTH]}
        bgColor="gray.50"
        p={['8px', '16px']}
        id="tax-chart"
        zIndex={1}
        border="1px solid"
        borderColor="gray.250"
        borderRadius={['4px', '2px']}
      >
        <Box w="100%" pos="relative">
          <HiddenInMobile>
            <Text
              fontWeight={700}
              color={isNotConnected ? 'gray.600' : 'purple.900'}
              top="15px"
              left="15px"
              mb="12px"
            >
              納税予測
            </Text>
          </HiddenInMobile>
          {/* グラフ上に表示するボタンや警告などの要素 */}
          <Box pos="relative" w="100%" h={['180px', '155px']}>
            <Center
              zIndex={10}
              pos="absolute"
              width="100%"
              height="100%"
              left={0}
              top={0}
            >
              {!isDemo &&
              isNotConnected &&
              reportEstimatedIncomeTax?.current_income_tax === 0 ? (
                <Flex flexDir="column" align="center" gap="8px">
                  <Text fontSize="16px" lineHeight="160%" fontWeight={700}>
                    まずは口座・クレカを連携！
                  </Text>
                  <Button
                    bgColor="primary.orange"
                    color="white"
                    borderRadius="6px"
                    p="10px 16px"
                    gap="6px"
                    fontSize="15px"
                    fontWeight="700"
                    lineHeight="150%"
                    boxShadow="0px 4px 20px rgba(0, 0, 0, 0.12)"
                    onClick={onModalOpen}
                  >
                    連携する
                  </Button>
                  <EncourageConnectionModal
                    onClose={onModalClose}
                    isOpen={isModalOpen}
                  />
                </Flex>
              ) : isSyncing ? (
                <Flex
                  bgColor="white"
                  align="center"
                  gap="6px"
                  py="10px"
                  px="12px"
                  borderRadius="2px"
                  border="1px solid"
                  borderColor="gray.200"
                >
                  <RefreshIcon animation={refreshIconAnimation} />
                  <Text fontWeight={700} lineHeight={1.5} fontSize="14px">
                    データ取得中（5分程度）
                  </Text>
                </Flex>
              ) : isEmpty ? (
                <Flex
                  bgColor="white"
                  align="center"
                  gap="6px"
                  py="10px"
                  px="12px"
                  borderRadius="2px"
                  border="1px solid"
                  borderColor="gray.200"
                >
                  <CheckIcon />
                  <Text fontWeight={700} lineHeight={1.5} fontSize="14px">
                    データ取得完了
                  </Text>
                </Flex>
              ) : isMinusTax ? (
                <Text
                  fontSize="15px"
                  lineHeight="24px"
                  fontWeight={700}
                  color="gray.600"
                  textAlign="center"
                >
                  お金が返ってくる
                  <HiddenInMobile>
                    <br />
                  </HiddenInMobile>
                  可能性があります
                </Text>
              ) : null}
            </Center>
            {/* 日付の表示はRechartsに頼るとおかしくなるので、自前で実装する */}
            {/* 1/1の日付ラベル */}
            <Text
              fontSize="10px"
              fontWeight={500}
              position="absolute"
              bottom="0px"
              left={['10px', '0px']}
              color="gray.700"
            >
              {startOfYearString}
            </Text>
            {/* 今日の日付ラベル */}
            {/* 現在日 > processing_yearの年末 になったら今日の日付ラベルは非表示にする */}
            {indexOfToday < 310 &&
              indexOfToday > 100 &&
              !isEmpty &&
              isTodaySameYearAsProcessingYear && (
                <Text
                  fontSize="10px"
                  fontWeight={700}
                  position="absolute"
                  bottom="0"
                  //  365 + 左右の余白20 * 2 = 405
                  // 244px(CHART_WIDTH) - 16px(padding) = 212px
                  left={[
                    (width * indexOfToday) / 405 - 5,
                    (212 * indexOfToday) / 405 - 5
                  ]}
                  color="primary.purple"
                >
                  {todayString}
                </Text>
              )}
            {/* 12/31の日付ラベル */}
            <Text
              fontSize="10px"
              fontWeight={500}
              position="absolute"
              right={['10px', '0px']}
              bottom="0"
              color="gray.700"
            >
              {format(
                endOfYear(
                  setYear(
                    nowDateObject,
                    processing_year?.processing_year ||
                      nowDateObject.getFullYear()
                  )
                ),
                'MM/dd'
              )}
            </Text>

            {/* グラフ本体 */}
            <ResponsiveContainer minHeight={100} minWidth={100}>
              <ComposedChart
                // グラフ上の点のプロットに使われるデータ
                // amountが実際の推移を、predictAmountが予想値用のデータ
                // 年始-今日(実際の推移), 今日-年末(予想値の推移)の２つにグラフは分かれる
                // 実際の推移と予想値の推移は今日の点で接続している
                data={[
                  // 左右の余白の調整のためにいれる
                  ...[...Array(20)].map(() => ({})),
                  // １月１日のx座標
                  {
                    amount: 0
                  },

                  ...[
                    ...Array(
                      // その年のすべての日数を計算し、年始と年末の２日を引いた数だけx座標を作る
                      daysCountInYear - 2
                    )
                  ].map((_, i) => {
                    // 会計年度と現実の年度が異なる場合は今日の日付のプロットを出さない
                    if (!isTodaySameYearAsProcessingYear) {
                      return { amount: null, predictAmount: null }
                    } else if (
                      // 今日の日付のプロットを表示させる
                      // data配列の要素数が363個のため、12/29~31も表示できるようロジックを追加
                      i === indexOfToday ||
                      (daysCountInYear - 3 < indexOfToday &&
                        i === indexOfToday - 3)
                    ) {
                      return {
                        amount: !isEmpty
                          ? // マイナス（還付金がある）のときは0にする
                            Math.max(
                              reportEstimatedIncomeTax.current_income_tax,
                              0
                            )
                          : 500000,
                        predictAmount: !isEmpty
                          ? Math.max(
                              reportEstimatedIncomeTax.current_income_tax,
                              0
                            )
                          : 500000
                      }
                    } else {
                      // nullにすることでラベルを表示させない
                      return { predictAmount: null }
                    }
                  }),
                  {
                    // 会計年度と現実の年度が異なるときは年始の0円と年末の推測値の２点のみを出す
                    amount:
                      !isEmpty && !isTodaySameYearAsProcessingYear
                        ? Math.max(
                            reportEstimatedIncomeTax.estimated_income_tax || 0,
                            0
                          )
                        : null,
                    predictAmount: !isEmpty
                      ? // マイナス（還付金がある）のときは0にする
                        Math.max(
                          reportEstimatedIncomeTax.estimated_income_tax,
                          0
                        )
                      : 600000
                  },
                  // 左右の余白の調整のためにいれる
                  ...[...Array(20)].map(() => ({}))
                ]}
                margin={{
                  top: 20,
                  right: 0,
                  bottom: -8
                }}
              >
                <CartesianGrid
                  stroke="#EAEAEA"
                  vertical={false}
                  strokeDasharray="4"
                />
                <XAxis
                  dataKey="name"
                  interval={0}
                  tickLine={false}
                  strokeWidth={1}
                  strokeOpacity={1}
                  stroke="#EAEAEA"
                  tick={false}
                />
                {/* 実際の推移のグラフ */}
                <Area
                  isAnimationActive={false}
                  type="linear"
                  dataKey="amount"
                  fill={!isEmpty ? '#6E7DCA' : '#0000000A'}
                  fillOpacity={1}
                  stroke={!isEmpty ? '#6E7DCA' : '#0000000A'}
                  connectNulls
                />
                {/* 予想値の推移のグラフ */}
                <Area
                  isAnimationActive={false}
                  connectNulls
                  type="linear"
                  dataKey="predictAmount"
                  fill={!isEmpty ? '#6E7DCA14' : '#00000005'}
                  fillOpacity={1}
                  stroke={!isEmpty ? '#CDCFE3' : '#D3D3D3'}
                  strokeDasharray="4 4"
                  strokeWidth="2px"
                  dot={{
                    stroke: 'white',
                    fill: !isEmpty ? '#6E7DCA' : '#D3D3D3',
                    r: 6,
                    strokeDasharray: '0'
                  }}
                >
                  <LabelList
                    dataKey="predictAmount"
                    content={(props) => {
                      const { x, y, value } = props
                      if (
                        // 12月になると実際の推移と文字が被ってしまうため、予想金額を出さない
                        // 12月31日は実際の金額と予測金額の金額が一緒になってしまうため、ラベルを表示する
                        // FIXME: 本当は予測金額のラベルを非表示にするというロジックを書きたいが、プロパティが存在しないため判断材料に納税額を使用している
                        nowDateObject.getMonth() + 1 === 12 &&
                        nowDateObject.getDate() !== 31 &&
                        value === reportEstimatedIncomeTax?.estimated_income_tax
                      ) {
                        return null
                      }

                      return isMinusTax ? (
                        <g transform={`translate(${x},${y})`}>
                          <PriceSvg value={0} x={0} y={-10} />
                        </g>
                      ) : !isEmpty && value !== undefined ? (
                        <g transform={`translate(${x},${y})`}>
                          <PriceSvg
                            value={Number(value)}
                            x={-8}
                            y={-3}
                            strokeWidth={4}
                            stroke="#fff"
                          />
                          {/* 文字に縁取りするために２つ重ねる */}
                          <PriceSvg value={Number(value)} x={-8} y={-3} />
                        </g>
                      ) : null
                    }}
                  />
                </Area>
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Box>
    )
  }
)
