import { Box, Flex, FlexProps, Text, VStack } from '@chakra-ui/react'
import { CautionIcon } from './Icons/CautionIcon'

export const AlertMessage: React.FC<
  {
    title: string | JSX.Element
    content?: JSX.Element
  } & Omit<FlexProps, 'title'>
> = ({ title, content, ...props }) => (
  <Flex
    align="flex-start"
    gap="12px"
    backgroundColor="cost.100"
    border="1px solid"
    borderColor="alert.300"
    borderRadius="2px"
    px="16px"
    py="8px"
    {...props}
  >
    <CautionIcon h="20px" w="20px" />
    <VStack alignItems="flex-start" spacing="6px">
      <Text fontSize="13px" fontWeight="700" lineHeight="1.5">
        {title}
      </Text>
      {content && <Box>{content}</Box>}
    </VStack>
  </Flex>
)
