import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useBoolean
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { useApiClient } from 'src/hooks/useApiClient'

export const EncourageConnectionModal: React.FC<{
  isOpen: boolean
  onClose: VoidFunction
}> = ({ isOpen, onClose }) => {
  const [isLoading, { on, off }] = useBoolean()
  const { apiClient } = useApiClient()

  const redirectToAccountTracker = useCallback(() => {
    on()
    const windowReference = window.open('', '_blank')
    apiClient.getAccountTrackerLink(location.href).then((res) => {
      off()
      if (windowReference) windowReference.location = res.redirect_to
    })
  }, [])
  return (
    <Modal
      variant="full"
      size="full"
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalCloseButton display={['none', 'block']} />

      <ModalContent
        // モーダルのコンテンツが少ないのでPCで画面全部を覆わないようにする
        // 開業届とかは画面全部を覆うようなデザインなので共通化はせずここだけの対応にする
        h={['fit-content', '100%']}
        sx={{
          '@supports(min-height: -webkit-fill-available)': {
            minH: 0
          }
        }}
      >
        <ModalBody
          bg="white.100"
          minH="fit-content"
          h="fit-content"
          maxW="480px"
          w="100%"
          pt={['0px', '64px']}
        >
          <Center
            pt={['40px', '45px']}
            pb={['12px', '30px']}
            bgColor="purple.50"
            borderRadius="16px"
          >
            <Image h="90px" src="/images/credit_card_with_pc.svg" />
          </Center>
          <Text
            textAlign="center"
            mt="12px"
            fontWeight={900}
            fontSize="22px"
            lineHeight={1.5}
          >
            クレカ・口座を連携して
            <br />
            作業時間を大幅カット！
          </Text>
          <Box bg="gray.70" borderRadius="8px" m="24px auto 0">
            <Box py="12px" bg="gray.200" borderTopRadius="8px">
              <Text
                textAlign="center"
                fontSize="16px"
                lineHeight="22.4px"
                fontWeight={700}
              >
                連携するもの
              </Text>
            </Box>
            <Flex
              p="16px"
              gap="12px"
              fontSize="13px"
              lineHeight="21px"
              fontWeight={700}
            >
              {[
                {
                  image: '/icons/credit_cards/normal.svg',
                  text: (
                    <>
                      事業の経費を支払う
                      <br />
                      クレジットカード
                    </>
                  )
                },
                {
                  image: '/icons/credit_cards/passbook.svg',
                  text: (
                    <>
                      左のクレカが
                      <br />
                      引き落される口座
                    </>
                  )
                }
              ].map((content) => (
                <Flex
                  w="100%"
                  key={content.image}
                  flexDir="column"
                  align="center"
                  pt="16px"
                  px="8px"
                  pb="16px"
                  borderRadius="16px"
                  bgColor="white.100"
                >
                  <Box h="50px">
                    <Image height="36px" src={content.image} />
                  </Box>
                  <Text align="center">{content.text}</Text>
                </Flex>
              ))}
            </Flex>
          </Box>

          <Flex mt="20px">
            <Button
              fontSize="14px"
              color="gray.600"
              fontWeight="700"
              lineHeight={1}
              variant="ghost"
              onClick={onClose}
              w="100px"
              h="60px"
            >
              戻る
            </Button>
            <Button
              h="60px"
              bgColor="primary.orange"
              fontSize="16px"
              fontWeight="700"
              lineHeight={1}
              isLoading={isLoading}
              w="100%"
              onClick={redirectToAccountTracker}
              justifyContent="space-between"
              boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.2)"
            >
              <Box w="24px" />
              <Text>連携する</Text>
              <ArrowForwardIcon fontSize="24px" />
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
